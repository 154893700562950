import {useState} from 'react'

export default function Post(props) {
    const post = props.post

    const [clicando, setClicando] = useState(false)

    const visita = (event) => {
        setClicando(true)
        fetch("https://api.devall.com.br/api/v1/post/clique/" + post.id)
            .then((response) => {
                if (! response.ok) {
                    alert("Erro ao buscar link do post")
                }
                return response.json()
            }).then((data) => {
                window.location = data.url
            }).catch((erro) => {
                setClicando(false)
                alert("Erro ao acessar post")
            })
        
    }

    return (
        <div className="window">
            <div className="title-bar" >
                <div onClick={visita} className="title" style={{cursor:'pointer', fontSize:'0.8em'}}>
                    {clicando ? "Abrindo..." : post.titulo}
                </div>
            </div>
            <div>
                <p>{post.resumo}</p>
            </div>
            <div>
                <p style={{fontSize:'0.6em'}}>{post.dataPublicacao} - Cliques {post.cliques}</p>
            </div>
        </div>
    )
}
import logo from './logo.svg';
import './App.css';
import {useState, useEffect} from 'react'
import Post from './Post';

function App() {

  const [search, setSearch] = useState("")
  const [posts, setPosts] = useState([])

  const onChange = (event) => {
    setSearch(event.target.value)
  }

  useEffect(() => {
    let url = "https://api.devall.com.br/api/v1/post"
   
    fetch(url)
      .then((response) => {
        if (! response.ok) {
          alert("Erro ao buscar posts no servidor: " + response.status)
        }
        return response.json()
        
      }).then((data) => {
        setPosts(data)
      })
      .catch((error) => {
        alert("Erro ao buscar posts no servidor: " + error)
      })
  }, [
    
  ])

  const onSearch = (event) => {
    
    let url = "https://api.devall.com.br/api/v1/post"
    if (search && search.trim().length > 0) {
      url = url + "?&search=" + search.trim()
    }
    window.fetch(url)
      .then((response) => {
        if (! response.ok) {
          alert("Erro ao buscar posts no servidor: " + response.status)
        }
        
        return response.json()
      }).then((data) => {setPosts(data)})
      .catch((error) => {
        alert("Erro ao buscar posts no servidor: " + error)
      })
    event.preventDefault()
  }



  return (
    <div className="window">
      <div className="title-bar">
      <button aria-label="Close" class="close"></button>
        <div className="title">/dev/All</div>
        <button aria-label="Resize" disabled className="hidden"></button>
      </div>
      <div className="separator"></div>
      <div className="modeless-dialog">
      
                <form onSubmit={onSearch}>
                <section className="field-row" style={{borderStyle:'solid 1px black', width:'100%'}}>
                  <label for="text_find" className="modeless-text">Encontrar posts:</label>
                  <input onChange={onChange} id="text_find" value={search} type="text"  placeholder=""/>
                  <input type="submit" className="btn" value="Buscar"/>
                  </section>
                </form>
      </div>

      <div className="modeless-dialog">
        {
          posts ?
            (posts.map((post) => (<Post key={post.id} post={post}/>))) :
            (<div>Carregando</div>)
        }
      </div>
      
    </div>
  );
}

export default App;
